<template>
	<div style="width: 700px; margin: 0 auto;">.
		<div style="height: 1104px; background-color: #ccc; ">
			
			<div style="font-size: 20px; font-weight: bold; text-align: center; padding-top: 150px;">
				{{planInfo.plan_name}}

			</div>
			<div style="text-align: center; padding: 20px; ">
				本次调查合计调查人数为{{gkInfo.stu_total}}人，作答人数为{{gkInfo.finish_ok}}人，
				参与率为{{gkInfo.percent}}%
			</div>
			<div style="margin-top: 400px;">
				<div class="flexBetween" style="text-align: center; padding: 0 50px ;">
					<div style="width: 300px;">
						<div class="boxitem">
							<img :src="'./img/bx_bg.png'" >
							<div class="boxdesc">
								需完成总人数：{{gkInfo.stu_total}}
							</div>

						</div>
						<div class="boxitem">
							<img :src="'./img/bx_bg.png'" >
							<div class="boxdesc">
								已完成人数：{{gkInfo.finish_ok}}
							</div>

						</div>
						<div class="boxitem">
							<img :src="'./img/bx_bg.png'" >
							<div class="boxdesc">
								未完成人数：{{gkInfo.finish_not}}
							</div>

						</div>
					</div>
					<div style="flex-grow: 1; text-align: center;">
						<img :src="'./img/plan_001.jpg'" alt="" style="max-width: 100%;max-height: 100%;">
					</div>
					<div style="width: 300px;">
						<div class="boxitem">
							<img :src="'./img/bx_bg.png'" >
							<div class="boxdesc">
								
								<div style="line-height: 30px;">
									小程序完成人数：{{gkInfo.type_mini}}人
									<br />
									占比{{gkInfo.type_mini_p}}%
								</div>

							</div>

						</div>
						<div class="boxitem">
							<img :src="'./img/bx_bg.png'" >
							<div class="boxdesc">
								
								<div style="line-height: 30px;">
									需完成总人数：{{gkInfo.type_sms}}人
									<br />
									占比{{gkInfo.type_sms_p}}%
									<br />
								</div>
							</div>
						</div>
						<div class="boxitem">
							<img :src="'./img/bx_bg.png'" >
							<div class="boxdesc">
								<div style="line-height: 30px;">
									需完成总人数：{{gkInfo.type_mail}}人
									<br />
									占比{{gkInfo.type_mail_p}}%
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-for="(tm,idx) in tjTmlist" :key="idx" style=" min-height: 350px; word-break: break-all; width: 100%; display: inline-block; 
		vertical-align: middle; padding-top: 20px; overflow: hidden;">
			<div style="font-size: 18px; font-weight: bold;">
				{{idx+1}}、{{tm.tm_title}}

			</div>
			<div>
				{{tm.desc}}
			</div>
			<div :id="'chart_'+idx" :style="{height: tm.tm_type=='tiankong'? (tm.tm_content.length*50 )+ 'px':'250px',pageBreakBefore:'always'}">

			</div>
		</div>
		<div class="laodok" id="pdfok" v-if="laodok">
			
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				pmid: 0,
				plan_id: 0,
				ivst_id: 0,
				planInfo: {},
				tjTmlist: [],
				gkInfo: {},
				laodok:false
			}
		},
		mounted() {
			this.plan_id = this.$route.query.id
      this.pmid = this.$route.query.pmid
			this.getGK()
		},
		methods: {


			getGK() {
				this.$http.post("/api/ivst_plan", {
					plan_id: this.plan_id,
				}).then(res => {
					this.planInfo = res.data
				})
				this.$http.post("/api/get_ivst_tj_gk", {
					plan_id: this.plan_id,
          pmid: this.pmid,
				}).then(res => {
					let gkInfo = res.data;
					if (gkInfo.finish_ok > 0) {
						gkInfo.type_mini_p = parseInt(gkInfo.type_mini / gkInfo.finish_ok * 10000) / 100
						gkInfo.type_sms_p = parseInt(gkInfo.type_sms / gkInfo.finish_ok * 10000) / 100
						gkInfo.type_mail_p = parseInt(gkInfo.type_mail / gkInfo.finish_ok * 10000) / 100

					} else {
						gkInfo.type_mini_p = 0
						gkInfo.type_sms_p = 0
						gkInfo.type_mail_p = 0
					}
					gkInfo.percent = parseInt(gkInfo.finish_ok / gkInfo.stu_total * 10000) / 100


					this.gkInfo = gkInfo
					this.getTongji()
				})
			},
			getTongji() {
				this.$http.post("/api/get_ivst_tj_tm_base", {
					plan_id: this.plan_id,
          pmid: this.pmid,
				}).then(res => {
					for (let item of res.data) {
						let arr = item.tm_content.sort(function(a, b) {
							return b.choose_count - a.choose_count
						})
						item.tm_content = arr;
						item.hasXX = false;
						if (item.tm_type.indexOf("radio") >= 0 || item.tm_type.indexOf("checkbox") >= 0) {
							item.hasXX = true
							item.desc = "位列前三位的依次是 ";
							for (let i = 0; i < 3; i++) {
								if (i < arr.length) {
									item.desc += arr[i].title + "（" + Math.round(arr[i].choose_count / this.gkInfo
										.finish_ok * 10000) / 100 + "%） "
								}
							}
						} else if(item.tm_type=='tiankong') {
							item.hasXX = true
							let tempDic={};
							for(let ii of item.answers){
                if (!ii.answers[0]) {
                  continue;
                }
                let words = ii.answers[0]
                if (words.length > 30) {
                  words = words.substring(0,30) + '...'
                }
								if(!tempDic[words]){
									tempDic[words]=1
								}else{
									tempDic[words]++
								}
							}
							let tempArr=[];
							for(let k in tempDic){
								tempArr.push({title:k,choose_count:tempDic[k]})
							}
							tempArr=tempArr.sort(function(a,b){return a.choose_count - b.choose_count})
							item.tm_content = tempArr
						}else {
							item.desc = item.answers.map(e=>{return e.answers[0]}).join(",")
						}

					}
					this.tjTmlist = res.data

					this.$nextTick(() => {
						setTimeout(() => {
							for (let i = 0; i < this.tjTmlist.length; i++) {
								this.initChartTM(i)
							}
							setTimeout(()=>{
								this.laodok = true
							},500)
						}, 300)
					})

				})
			},
			initChartTM(idx) {
				let colorList = ['#73c0de', '#91cc75', '#fac858', '#ee6666', '#3ba272',
					'#fc8452', '#9a60b4', '#ea7ccc', '#5470c6'
				]

				if (this.tjTmlist[idx].hasXX) {

					let data = this.tjTmlist[idx].tm_content
					let myChart1 = this.$echarts.init(document.getElementById('chart_' + idx))


					let option = {

						tooltip: {
							trigger: 'axis',
							axisPointer: {
								type: 'shadow'
							}
						},

						grid: {
							left: '3%',
							right: '4%',
							bottom: '3%',
							containLabel: true
						},
						xAxis: {
							type: 'value',
							boundaryGap: [0, 0.01]
						},
						yAxis: {
							type: 'category',
							data: data.map(e => {
								return e.title
							})
						},
						series: [{
								name: '选择人数',
								type: 'bar',
								barWidth: 15,
                label: {
                  show:true,
                  position: 'right'
                },
								itemStyle: {
									color: function(p) {
										return colorList[p.dataIndex]
									}
								},
								data: data.map(e => {
									return e.choose_count
								})
							},

						]
					};
					myChart1.setOption(option)
				}
			}


		}
	}
</script>

<style scoped="scoped">
	.boxitem {
		width: 220px;
		height: 90px;
		display: inline-block;
		
		background-size: 100% 100%;
		margin-bottom: 20px;
		color: #4dadea;
		font-size: 16px;
		background-color: #ecf5fc;
		border-radius: 8px;
		position: relative;

	}
	.boxitem img{ position: absolute; width: 100%; height: 100%; z-index: 0; left: 0;}


	.boxdesc {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		position: relative;
		z-index: 1;
	}
</style>
